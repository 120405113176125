import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
// import store from './authentication/store';
import '@mdi/font/css/materialdesignicons.min.css';
import AuthenticationModal from './authentication/authenticationModal.vue';
// import { BCard, BCardText, BTabs, BTab } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'



// Vue.config.productionTip = false;
// Vue.component('b-card', BCard)
// Vue.component('b-card-text', BCardText)
// Vue.component('b-tabs', BTabs)
// Vue.component('b-tab', BTab)
Vue.config.productionTip = false;

// Register modal globally
Vue.prototype.$authModal = new Vue({
  render: h => h(AuthenticationModal)
}).$mount();


  new Vue({
    vuetify,
    router, 
    render: h => h(App)
  }).$mount('#app');

  console.log('Authentication modal registered:', Vue.prototype.$authModal);
