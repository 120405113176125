<template>
  <div class="login-container">
    <!-- Flash messages -->
    <transition name="fade">
      <v-alert
        v-if="flashMessage"
        class="flash-message"
        dense
        border="right"
        :type="flashType"
        dark
      >
        {{ flashMessage }}
      </v-alert>
    </transition>

    <!-- Login form -->
    <form @submit.prevent="login" class="login-form">
      <div class="form-group">
        <div class="input-group">
          <v-icon>mdi-account</v-icon>
          <v-text-field type="text" v-model="username" label="Username" required> </v-text-field>
        </div>
      </div>
      <div class="form-group">
        <v-text-field
          :type="showPassword ? 'text' : 'password'"
          id="password"
          v-model="password"
          label="Password"
          required
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
      </div>
      <div class="form-group">
        <input type="checkbox" id="rememberMe" v-model="rememberMe">
        <label for="rememberMe">Remember Me</label>
      </div>
      <button type="submit" class="login-button" :disabled="loading" @click="autoSubmitIfRememberMe">
        <span v-if="loading">Logging in...</span>
        <span v-else>Login</span>
        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
      </button>
    </form>
  </div>
</template>

<script>
// import { link } from '../config/link';
// import { getApiUrl } from '../config/configService.js';
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
      flashMessage: '',
      flashType: '',
      token: '',
      loading: false,
      rememberMe: false,
      showPassword: false,
    };
  },
  methods: {
    async login() {
      this.loading = true;
      try {
        const apiUrl = process.env.VUE_APP_API_URL; // Menggunakan fungsi dari configService.js
        const response = await axios.post(`${apiUrl}/auth/login`, {
            username: this.username,
            password: this.password
        });
        const token = response.data.token;
        const username = response.data.username;
        const username_ceisa = response.data.username_ceisa;
        const id = response.data.id;
        localStorage.setItem('token', token);
        localStorage.setItem('username', username);
        localStorage.setItem('username_ceisa', username_ceisa);
        localStorage.setItem('id', id);
        this.token = token;
        this.flashType = 'success';

        if (this.rememberMe) {
          localStorage.setItem('rememberMeUsername', this.username);
          localStorage.setItem('rememberMePassword', this.password);
        }
         // Simpan flash message di local storage
        localStorage.setItem('flashMessage', `Welcome, ${username}!`);
        localStorage.setItem('justLoggedIn', true);
        localStorage.setItem('justLoggedIn2', true);
        // localStorage.setItem('flashType', 'success');
        this.$router.push({ name: 'home'});
        // await this.$router.push('/');
        window.location.reload();
      } catch (error) {
        this.flashMessage = 'Incorrect username or password. Please try again.';
        setTimeout(() => {
          this.flashMessage = null;
        }, 3000);
        this.flashType = 'error';
        this.loading = false;
      }
    },
    autoSubmitIfRememberMe() {
      if (this.rememberMe && this.username && this.password) {
        this.login();
      }
    },
  },
  created() {
    const token = localStorage.getItem('token');
    const rememberMeUsername = localStorage.getItem('rememberMeUsername');
    const rememberMePassword = localStorage.getItem('rememberMePassword');
    if (token) {
      this.token = token;
    }
    if (rememberMeUsername && rememberMePassword) {
      this.username = rememberMeUsername;
      this.password = rememberMePassword;
      this.rememberMe = true;
    }
  },
};
</script>

<style scoped>
.login-container {
  overflow: hidden;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../assets/images/sintact.jpg'); /* add your background image URL here */
  background-size: cover;
  background-position: center;
}

.login-form {
  width: 25%;
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.692); /* glass effect */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px); /* glass effect */
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input-group {
  display: flex;
  align-items: center;
}

v-icon {
  margin-right: 10px;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.5); /* glass effect */
}

.login-button {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.2); /* glass effect */
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.login-button:hover {
  background-color: rgba(0, 0, 0, 0.4); /* glass effect */
  color: #fff;
}

.login-button:disabled {
  background-color: rgba(0, 0, 0, 0.1); /* glass effect */
  cursor: not-allowed;
}

.flash-message {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  padding: 10px;
  border-radius: 4px;
  font-weight: bold;
  text-align: center;
  animation: slideInRight 0.5s ease-in-out forwards;
}

.flash-message.success {
  background-color: #28a745;
  color: #fff;
}

.flash-message.error {
  background-color: #dc3545;
  color: #fff;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
</style>