<template>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex text-center>
          <div class="moving-element"></div>
          <v-btn color="primary" href="/">Go to Home</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'NotFound'
  };
  </script>
  
  <style scoped>
  /* Styling for Page Not Found */
  .moving-element {
  width: 350px;
  height: 350px;
  margin-left: 440px;
  background-image: url('https://img.freepik.com/free-vector/oops-404-error-with-broken-robot-concept-illustration_114360-1932.jpg');
  background-size: cover;
  animation: moveElement 2s infinite alternate;
}
  
  @keyframes moveElement {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-20px);
    }
  }
  </style>
  