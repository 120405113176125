import qs from 'qs';
// import { settings } from "@/config/settings";

export function appendQueryString(url, params) {
  const queryString = qs.stringify(params);
  if (queryString === '') {
    return url;
  } else if (url.includes('?')) {
    return `${url}&${queryString}`;
  } else {
    return `${url}?${queryString}`;
  }
}