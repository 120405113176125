<template>
  <v-dialog v-model="showModal" persistent>
    <v-card>
      <v-card-title>Login Required</v-card-title>
      <v-card-actions>
        <v-btn @click="handleLogin">Login</v-btn>
        <v-btn @click="handleCancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    handleLogin() {
      this.$emit('authenticated');
      this.hide();
    },
    handleCancel() {
      this.hide();
    },
  },
};
</script>
