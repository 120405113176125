<template>
  <v-app>
    <!-- Menampilkan NavBar hanya jika route bukan '/login' -->
    <NavBar v-if="$route.path !== '/login'"/>
    <!-- Menampilkan router-view untuk konten halaman -->
    <router-view v-if="$route.path == '/login'"/>
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar'

export default {
  name: 'App',

  components: {
    NavBar, // Menggunakan komponen NavBar yang diimpor
  },

  mounted() {
    // Mencegah klik kanan
    document.addEventListener('contextmenu', this.preventRightClick);

    // Mencegah shortcut DevTools seperti Ctrl+Shift+I dan F12
    document.addEventListener('keydown', this.preventDevToolsShortcuts);
  },

  beforeDestroy() {
    // Hapus event listener saat komponen dihancurkan
    document.removeEventListener('contextmenu', this.preventRightClick);
    document.removeEventListener('keydown', this.preventDevToolsShortcuts);
  },

  methods: {
    // Fungsi untuk mencegah klik kanan
    preventRightClick(event) {
      event.preventDefault();
    },

    // Fungsi untuk mencegah DevTools shortcuts
    preventDevToolsShortcuts(event) {
      // Cek jika F12 atau Ctrl+Shift+I ditekan
      if (
        (event.key === 'F12') ||
        (event.ctrlKey && event.shiftKey && event.key === 'I')||
        (event.ctrlKey && event.shiftKey && event.key === 'J')||
        (event.ctrlKey && event.shiftKey && event.key === 'P')||
        (event.ctrlKey && event.shiftKey && event.key === 'C')
      ) {
        event.preventDefault();
      }
    }
  },
};
</script>
